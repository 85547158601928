import React, { useCallback, useEffect, useState } from 'react';
import {
  Avatar,
  Badge,
  Button,
  Drawer,
  Layout,
  Menu,
  notification,
  Typography,
} from 'antd';

import { ReactComponent as ExportOutlined } from 'assets/icons/ExportOutlined.svg';
import { ReactComponent as LogoIcon } from 'assets/icons/Logo.svg';
import { useAuthContext } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { matchPath, useHistory } from 'react-router';
import navigationConfig, {
  MenuItem,
  navigationMenus,
} from 'routes/navigationConfig';
import BaseService from 'services/api/BaseService';
import { Header } from 'antd/es/layout/layout';
import {
  BellFilled,
  MenuOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import clsx from 'clsx';

const { Content, Sider } = Layout;

const AppLayout = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const { auth, logout } = useAuthContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const pathname = history.location.pathname;
  const handleLogout = useCallback(() => {
    logout();
    history.push('/connexion');
  }, [history, logout]);

  const handleMenuClick = (e: MenuItem) => {
    if (typeof e?.key === 'string') history.push(e.key);
  };

  const visibilityChange = () => {
    if (document.visibilityState === 'visible' || !document.hidden) {
      BaseService.checkToken();
    }
  };

  const handleTokenExpired = useCallback(() => {
    handleLogout();
    notification.info({
      message: t('global:session_expired'),
    });
  }, [handleLogout, t]);

  useEffect(() => {
    window.addEventListener('onTokenExpired', handleTokenExpired);
    document.addEventListener('visibilitychange', visibilityChange);
    return () => {
      window.removeEventListener('onTokenExpired', handleTokenExpired);
      document.removeEventListener('visibilitychange', visibilityChange);
    };
  }, [handleTokenExpired]);

  const items = navigationConfig(handleMenuClick);

  const defaultSelectedKeys =
    navigationMenus.find((item) =>
      matchPath(pathname, {
        path: `${item.key}${item.key === '/' ? '' : '*'}`,
        exact: true,
      }),
    )?.key ?? '/';

  const slider = (className: string) => (
    <Sider
      className={clsx(
        'slider-override flex md:m-4 lg:m-7 rounded-lg flex-col',
        className,
      )}
      width={240}
    >
      <div className="flex flex-col items-center gap-4 pt-6">
        <Avatar
          className={'bg-white bg-opacity-25 w-16 h-16'}
          icon={<UserOutlined size={32} />}
        />
        <Typography.Text
          ellipsis
          className={'text-white text-base font-medium'}
        >
          {auth?.user.firstname} {auth?.user.lastname}
        </Typography.Text>
      </div>
      <hr className={'mx-4 mt-8 mb-4 bg-white opacity-50'} />
      <Menu
        theme={'dark'}
        selectedKeys={[`${defaultSelectedKeys}`]}
        mode="inline"
        activeKey={`${defaultSelectedKeys}`}
        items={items}
      />
      <div style={{ flexGrow: 1 }} />
      <Button
        icon={<ExportOutlined />}
        iconPosition={'end'}
        onClick={handleLogout}
        type={'default'}
        className={'m-4 bg-transparent text-white border-white'}
      >
        {t('global:logout')}
      </Button>
    </Sider>
  );

  return (
    <>
      <Drawer
        placement={'left'}
        className={'flex md:hidden items-stretch flex-col !bg-primary'}
        bodyStyle={{ padding: 0 }}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        {slider(
          '!w-full h-full !max-w-full [& .ant-menu]:w-full [&>div]:h-full',
        )}
      </Drawer>
      <Layout style={{ height: '100vh' }}>
        <Header
          className={
            'flex items-center justify-between bg-white lg:h-[88px] md:px-8 px-4'
          }
        >
          <LogoIcon className={'lg:w-[146px] w-[85px]'} />
          <Typography.Title
            level={1}
            className={
              'hidden md:flex md:!text-2xl lg:!text-3xl leading-none !m-0'
            }
          >
            {t('global:hero')}
          </Typography.Title>
          <div className={'flex gap-2 items-center'}>
            <Button
              type={'primary'}
              icon={<PlusOutlined />}
              iconPosition={'end'}
            >
              {t('global:rdv')}
            </Button>
            <Badge count={3}>
              <Button
                className={'text-orange min-h-0'}
                icon={<BellFilled />}
                shape={'circle'}
              />
            </Badge>
            <Button
              onClick={() => setMenuOpen(true)}
              className={'flex md:hidden'}
              type={'text'}
              icon={<MenuOutlined />}
            />
          </div>
        </Header>
        <Layout className={'items-stretch bg-layout'}>
          {slider('hidden md:flex')}
          <Layout className={'bg-layout'}>
            <Content className={'overflow-auto py-7 md:pl-0 md:pr-7 px-4'}>
              {children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default React.memo(AppLayout);
