import React from 'react';
import { theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from 'assets/icons/Logo.svg';

const AuthLayout = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const { token } = theme.useToken();
  const { t } = useTranslation();
  return (
    <div
      style={{
        height: '100vh',
        backgroundColor: token.colorBgLayout,
      }}
    >
      <div
        className={
          'flex items-center justify-between max-w-screen-xl h-full mx-auto overflow-auto gap-32 p-6'
        }
      >
        <Typography.Title
          className={'hidden md:flex'}
          style={{
            color: token.colorPrimaryText,
            whiteSpace: 'pre-line',
          }}
          level={1}
        >
          {t('auth.hero')}
        </Typography.Title>
        <div
          style={{ minHeight: 'calc(100vh - 240px)' }}
          className={
            'lg:px-20 md:px-8 px-4 py-20 bg-white rounded-lg flex flex-col justify-center items-start'
          }
        >
          <div className={'mb-6'}>
            <Logo />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default React.memo(AuthLayout);
